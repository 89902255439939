import { Fragment, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setDrawerStatus } from "../store/global/global.action"
import { selectMainProduct } from "../store/global/global.selector"
import {  setProductOneConnectorColor, setProductThreeConnectorColor, setProductTwoConnectorColor } from "../store/product/product.action"
import {  selectProductOneConnectorColor, selectProductThreeConnectorColor, selectProductTwoConnectorColor } from "../store/product/product.selector"

const ConnectorColor = () => {
const dispatch = useDispatch()
const mainProduct = useSelector(selectMainProduct)
const productOneConnector = useSelector(selectProductOneConnectorColor)
const productTwoConnector = useSelector(selectProductTwoConnectorColor)
const productThreeConnector = useSelector(selectProductThreeConnectorColor)
const [selectedColor,setSelectedColor] = useState('black')
    const handleConnectorColor = (e) => {
      dispatch(setDrawerStatus('closed'))
      switch(mainProduct){
        case 'productOne':
          dispatch(setProductOneConnectorColor(e.target.value))
          break;
        case 'productTwo':
          dispatch(setProductTwoConnectorColor(e.target.value))
          break;
          case 'productThree':
            dispatch(setProductThreeConnectorColor(e.target.value))
            break;

          default:
            dispatch(setProductOneConnectorColor(e.target.value))
          break;
  
      }
        
    }

    useEffect(()=>{
      console.log('callllllllllllllll',mainProduct)
      switch(mainProduct){
          case 'productOne':
            setSelectedColor(productOneConnector);
            break;
          case 'productTwo':
            setSelectedColor(productTwoConnector)
            break;
            case 'productThree':
              setSelectedColor(productThreeConnector)
            break;
        
            default:
              setSelectedColor(productOneConnector)
            break;
        
        }
        console.log(selectedColor,'ssssssssss')
    },[ mainProduct,productOneConnector,productThreeConnector,productTwoConnector,selectedColor])


    return (
       <Fragment>
      <label>Connector Color</label>
        <div className="radion-button-row second-radion-button-row">
          <div className="radion-button-column"><input name="connector-color" onClick={handleConnectorColor} checked={selectedColor==='black' ? true :false} type="radio" value="black" id="color-black"/><label htmlFor="color-black">Black</label>
            </div>
            <div className="radion-button-column"><input name="connector-color" onClick={handleConnectorColor} checked={selectedColor==='white' ? true :false} type="radio" value="white" id="color-white"/><label htmlFor="color-white">White</label>
            </div>
          </div>
          </Fragment>
    )

}
export default ConnectorColor