import { useDispatch } from "react-redux";
import { setColor, setDrawerStatus } from "../store/global/global.action";


const WireSelector = ({wire}) => {
  const dispatch = useDispatch();
  const globalSetHandler = () => {
    dispatch(setDrawerStatus('closed'))
    dispatch(setColor(wire))
  }
    return (
      
        <div onClick={globalSetHandler} className="cableColor-column">
        <input type="radio" name="cableColor" value="" id={`cableColor-${wire}`}/>
        <label htmlFor={`cableColor-${wire}`}>
          <figure><img src={`${process.env.PUBLIC_URL}images/cableColor/${wire}-wire.png`} alt=""/></figure>
        </label>
      </div>
    )
}

export default WireSelector;