import { Fragment, useState } from "react"
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectGlobalColor, selectMainProduct } from "../store/global/global.selector";
import { setProductOneWireColors } from "../store/product/product.action";
import {  selectProductOneConnectorColor, selectProductOneWireColors } from "../store/product/product.selector";
import Wire from "./wire.component";

const ProductOne = () => {
const dispatch = useDispatch();
 const wireColors = useSelector(selectProductOneWireColors)
 const connectorColor = useSelector(selectProductOneConnectorColor)
 const globalColor = useSelector(selectGlobalColor);
 const mainProduct = useSelector(selectMainProduct
  )
  const changeHandler = (index) => {
    if(mainProduct === 'productOne'){
      const cloneWire = [...wireColors];
      cloneWire[index] = globalColor
      dispatch(setProductOneWireColors(cloneWire))
    }
  }
    return (
        <Fragment>
        <div className="wireGallery-body">
          <div className="wireGallery-wrapper">
            {wireColors.map((wire,i)=>{
              return (<span onClick={()=>changeHandler(i)} key={i}><Wire wire={wire} /></span>)
            })}

          </div>
          <div className={`wireGallery-body-figure-box ${wireColors.length ===7 ? 'pin-14' : ''} ${wireColors.length ===11 ? 'pin-22' : ''} ${wireColors.length ===12 ? 'pin-24' : ''}`}>
     
          <figure className="wireGallery-body-figure"><img src={`${process.env.PUBLIC_URL}images/motherboard_${connectorColor}.png`} alt=""/></figure>
        </div>
        </div>
        <div className="w-100 wireGallery-title">
      <p>24 PIN <br/>CPU</p>
    </div>
      
      </Fragment>
    )
}
export default ProductOne