const Header = () => {

return (
  <header className="vpc_header col-12" id="header">
  <div className="row">
    <div className="container">
    <div className="menu-overlay position-fixed"></div>
      <nav className="navbar navbar-expand-lg vpc_navbar">                                 
          <div className="w-100 d-flex flex-wrap header-bottom-row">
            <div className="navbar-logo d-flex align-items-center flex-shrink-0">
              <button className="navbar-toggler d-block d-lg-none p-0 " type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <img src={`${process.env.PUBLIC_URL}/images/navbar.png`}  />
              </button>
              <a href="https://voltedpc.in" className="navbar-brand">
              <img className="logo" src={`${process.env.PUBLIC_URL}/images/logo_img.png`} />
              </a>
            </div>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <div className="mobileNavbar-logo">
                <a href="https://voltedpc.in" className="navbar-brand">
                <img className="logo" src="https://voltedpc.in/assets/images/logo_img.png" />
                </a>
              </div>
              <ul className="navbar-nav">
                  <li i className="dropdown SelectConfiguration-dropdowncta-menu">
                     <a  href="#" data-toggle="dropdown" aria-expanded="false"><span>Volted PC</span><i></i> </a>
                    <div className="dropdown-menu">
                      <ul>
                        <li><a href="https://voltedpc.in/build-pc"><span>Build Your PC</span></a></li>
                        <li><a className="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span>Pre - Configured</span><i></i> </a>
                          <ul class="dropdown-menu submenu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                            <li><a className="dropdown-item" href="https://voltedpc.in/category/gaming-pcs">Gaming</a></li>
                            <li><a className="dropdown-item" href="https://voltedpc.in/category/home-office-pcs">Home/Office Use</a></li>
                           <li className="dropdown-submenu"><a  href="#" className="dropdown-item dropdown-toggle"><span>Workstation </span><i></i> </a>

                            <ul className="submenu dropdown-menu dropdown-menu-right">
                              <li><a className="dropdown-item" href="https://voltedpc.in/category/video-editing-workstation"><span>Video editing</span></a></li>
                              <li><a className="dropdown-item" href="https://voltedpc.in/category/photo-editing-workstation"><span>Photo editing</span></a></li>
                              <li><a className="dropdown-item" href="https://voltedpc.in/category/stock-trading-workstation"><span>Stock trading</span></a></li>
                              <li><a className="dropdown-item" href="https://voltedpc.in/category/animation-and-rendering-workstation"><span>Animation/3d rendering</span></a></li>
                              <li><a className="dropdown-item" href="https://voltedpc.in/category/ai-and-machine-learning-workstation"><span>Machine learning</span></a></li>
                            </ul>



                           </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </li>


                  <li className="dropdown">
                    <a  href="#" data-toggle="dropdown" aria-expanded="false"><span>Volted Mods</span><i></i> </a>
                      <div className="dropdown-menu">
                     <ul><li><a href="http://voltedmods.in/">Custom Sleeved Cable</a></li> <li><a href="https://voltedpc.in/mods"><span>Custom Fans</span></a></li></ul>
                    </div>
                  </li>

                  <li><a href="https://voltedpc.in/services"><span>Services</span></a></li>

                  <li className="dropdown">
                    <a  href="#" data-toggle="dropdown" aria-expanded="false"><span>More about us</span><i></i> </a>
                      <div className="dropdown-menu">
                     <ul><li><a href="https://voltedpc.in/about-us">Our Company</a></li> <li><a href="https://voltedpc.in/gallery"><span>Our Portfolio</span></a></li></ul>
                    </div>
                  </li>



              </ul>
              <div className="vpc_mobileMenuright_column w-100">
                <a href="https://voltedpc.in/build-pc" className="vpc_default_redgradint_cta"><span>Build Your PC</span></a>
              </div>
            </div>
           <div className="d-flex align-items-center flex-shrink-0 vpc_menuright_column"><a href="https://voltedpc.in/build-pc" className="vpc_default_redgradint_cta menuBuildyour_pc"><span>Build Your PC</span></a>  </div>
          </div>
        
      </nav>
    </div>
  </div>

</header>
)

}

export default Header;