const Footer = () =>{
return (
    <footer className="col-12 footer">
    <div className="container">
      <div className="row">
        <div className="w-100 w-100 d-flex flex-wrap justify-content-center align-items-center footer-menu-container">
          <div className="footer-menu-column">
            <a href="https://voltedpc.in/about-us">About Us</a>
            <a href="https://voltedpc.in/contact-us">Contact Us</a>
          </div>
          <div className="footer-menu-column">
            <a href="https://voltedpc.in/terms-of-service">T&C</a>
            <a href="https://voltedpc.in/privacy-policy">Privacy Policy</a>
          </div>
          <div className="footer-menu-column">
            <a href="#"><i className="fa-brands fa-facebook-f"></i></a>
            <a href="#"><i className="fa-brands fa-instagram"></i></a>
          </div>
        </div>
      </div>
    </div>
  </footer>
)
}
export default Footer;