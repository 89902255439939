import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectGlobalColor, selectMainProduct } from "../store/global/global.selector"
import { setProductThreeWireColors } from "../store/product/product.action"
import { selectProductThreeConnectorColor, selectProductThreeWireColors } from "../store/product/product.selector"
import CableSelector from "./cable-selector.component"
import CombColor from "./comb-color.component"
import ConnectorColor from "./connector-color.component"
import Wire from "./wire.component"

const ProductThree = () => {

  const dispatch = useDispatch()
  const wireColors = useSelector(selectProductThreeWireColors)
  const connectorColor = useSelector(selectProductThreeConnectorColor)
  const globalColor = useSelector(selectGlobalColor);
  const mainProduct = useSelector(selectMainProduct)
   const changeHandler = (index) => {
    if(mainProduct === 'productThree'){
      const cloneWire = [...wireColors];
      cloneWire[index] = globalColor
      dispatch(setProductThreeWireColors(cloneWire))
    }
   }


    
return (
  <Fragment>
    <div className="wireGallery-body">
      <div className="wireGallery-wrapper">
       {wireColors.map((wire,i)=>{
        return (<span onClick={()=>changeHandler(i)} key={i}><Wire wire={wire}/></span>)
       })}
      </div>
      <div className={`wireGallery-body-figure-box ${wireColors.length ===7 ? 'pin-14' : ''} ${wireColors.length ===11 ? 'pin-22' : ''} ${wireColors.length ===12 ? 'pin-24' : ''}`}>
      <figure className="wireGallery-body-figure"><img src={`${process.env.PUBLIC_URL}images/cpu_${connectorColor}.png`} alt=""/></figure>
      {(wireColors.length ===8) ? <figure className="wireGallery-body-figure"><img src={`${process.env.PUBLIC_URL}images/gpu_${connectorColor}.png`} alt=""/></figure>
       : '' }
      </div>
    
    </div>
    <div className="w-100 wireGallery-title">
      <p>CPU Cable</p>
    </div>
   
    </Fragment>

)
}
export default ProductThree