import { Fragment } from "react"
import { useDispatch, useSelector } from "react-redux"
import { selectGlobalColor, selectMainProduct } from "../store/global/global.selector"
import { setProductTwoWireColors } from "../store/product/product.action"
import { selectProductTwoConnectorColor, selectProductTwoWireColors } from "../store/product/product.selector"
import Wire from "./wire.component"
import CableSelector from "./cable-selector.component"
import CombColor from "./comb-color.component"
import ConnectorColor from "./connector-color.component"

const ProductTwo = () =>{

  const dispatch = useDispatch()
  const wireColors = useSelector(selectProductTwoWireColors)
  const connectorColor = useSelector(selectProductTwoConnectorColor)
  const globalColor = useSelector(selectGlobalColor);
  const mainProduct = useSelector(selectMainProduct)
  
   const changeHandler = (index) => {
    if(mainProduct === 'productTwo'){
      const cloneWire = [...wireColors];
      cloneWire[index] = globalColor
      dispatch(setProductTwoWireColors(cloneWire))
    }
   }

return (
  <Fragment>
    <div className="wireGallery-body">
      <div className="wireGallery-wrapper">
      {wireColors.map((wire,i)=>{
        return (<span onClick={()=>changeHandler(i)} key={i}><Wire wire={wire}/></span>)
       })}
      </div>
      
        <div className={`wireGallery-body-figure-box ${wireColors.length ===7 ? 'pin-14' : ''} ${wireColors.length ===11 ? 'pin-22' : ''} ${wireColors.length ===12 ? 'pin-24' : ''}`} >
      <figure className="wireGallery-body-figure"><img src={`${process.env.PUBLIC_URL}images/gpu_${connectorColor}.png`} alt=""/></figure>
      {(wireColors.length ===8 || wireColors.length ===11) ? <figure className="wireGallery-body-figure"><img src={`${process.env.PUBLIC_URL}images/gpu_${connectorColor}.png`} alt=""/></figure>
       : '' }
         {(wireColors.length ===7 || wireColors.length ===11) ? <figure className="wireGallery-body-figure"><img src={`${process.env.PUBLIC_URL}images/cpu_black_3.png`} alt=""/></figure>
       : '' }
         {(wireColors.length ===12) ? <figure className="wireGallery-body-figure"><img src={`${process.env.PUBLIC_URL}images/gpu_${connectorColor}.png`} alt=""/></figure>
       : '' }
        {(wireColors.length ===12) ? <figure className="wireGallery-body-figure"><img src={`${process.env.PUBLIC_URL}images/gpu_${connectorColor}.png`} alt=""/></figure>
       : '' }
      
    </div>
    </div>
    <div className="w-100 wireGallery-title">
      <p>PCIE Cable</p>
    </div>

  </Fragment>
)
}
export default ProductTwo