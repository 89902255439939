import { useState } from "react";
import { useSelector } from "react-redux";
import {
  selectProductOneWireColors,
  selectProductThreeWireColors,
  selectProductTwoWireColors,
} from "../store/product/product.selector";
import wires from "../utils/wires";
import CombColor from "./comb-color.component";
import ConnectorColor from "./connector-color.component";
import WireSelector from "./wire-selector.component";
import { useForm } from "react-hook-form";
import axios from "axios";
import html2canvas from 'html2canvas';

const Configurator = () => {
  const [currentStage, setCurrentStage] = useState("initial");
  const [payDisable,setPayDisable] = useState(false);
  const productThreeWires = useSelector(selectProductThreeWireColors);
  const productTwoWires = useSelector(selectProductTwoWireColors);
  const productOneWires = useSelector(selectProductOneWireColors);

  const valueCalc = {
    1: [{ 'wire':12,'value': 1200 }],
    2: [{ 'wire':4,'value':  600 }, {'wire': 7,'value':  1000 }, {'wire': 8,'value':  1200 }, {'wire': 11,'value':  1600 }, {'wire': 12,'value':  1800 }],
    3: [{ 'wire':4,'value':  500 }, {'wire': 8,'value':  1000 }]
  };
// console.log(valueCalc[1].find(o => o.wire === 12).value,'valueCalc[1][12]')
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const onSubmit = async (data) => {
//     html2canvas(document.querySelector(".wireGallery-view-container")).then(canvas => {
//       var screen241 = canvas.toDataURL();
// 		screen241 = screen241.replace(/^data:image\/png/, "").replace(';base64,', "");
// 		// setTimeout(function(){
//       let img1Form = new FormData();
//       img1Form.append("key","b0d19a2014f3bcb3bacc179a19767eac");
//       img1Form.append('image',screen241)
// 		axios({

// 			url: 'https://api.imgbb.com/1/upload',
// 			method: 'post',
// 			data: img1Form,
// 			success: function (response) {
//         console.log(response,'response')
// 				// imgArray1 = response.data.display_url;
// 			}
//   });
// });
//     return false;

    setPayDisable(true)
    let ProductArray = {
      1: "1|" + productOneWires.filter(Boolean).toString(),
      2: "2|" + productTwoWires.filter(Boolean).toString(),
      3: "3|" + productThreeWires.filter(Boolean).toString(),
    };

    let bodyFormData = new FormData();
    bodyFormData.append("customerName", data.customerName);
    bodyFormData.append("phoneNumber", data.mobileNumber);
    bodyFormData.append("customerEmail", data.email);
    bodyFormData.append("customerAddress", data.address);
    bodyFormData.append("customerPincode", data.pincode);
    bodyFormData.append("products", JSON.stringify(ProductArray));
    bodyFormData.append('combTotal','0')

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("something wrong. Are you online?");
      return;
    }

    const result = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/pay`,
      data: bodyFormData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    console.log(result, "resultresult");
    if (!result || result.status!==200) {
      alert("Server error. Are you online?");
      return;
    }
console.log(result.data,'result')
    const { order_total,order_number} = result.data.order;
    setPayDisable(false)
    const options = {
      key: `${process.env.REACT_APP_RZP_KEY}`, // Enter the Key ID generated from the Dashboard
      amount: order_total.toString(),
      currency: 'INR',
      name: "Volted PC",
      description: "Custom Cable Configurator",
      image: `${process.env.PUBLIC_URL}/images/logo.jpg`,
      order_id: order_number,
      handler: async function (response) {
       console.log(response,'responseresponseresponseresponseresponseresponseresponse')
        const formParams = new FormData();
          formParams.append('razorpay_payment_id',response.razorpay_payment_id)
          formParams.append('razorpay_order_id',response.razorpay_order_id)
          formParams.append('razorpay_signature',response.razorpay_signature)
          formParams.append('combTotal','0')
        // "images": [imgArray1, imgArray2, imgArray3],
        setPayDisable(true)
        const result1 = await axios({
          method: "post",
          url: `${process.env.REACT_APP_API_HOST}/pay-confirmation`,
          data: formParams,
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (!result1 || result1.status!==200) {
          alert('something wrong, please contact us')
        }

        if(result1.data.status==='1'){
          alert('Thanks for ordering! please check your email')
          window.location.reload()
        }else{
          alert('something wrong, please contact us')
        }

      },
      prefill: {
        name: data.customerName,
        email: data.email,
        contact: data.mobileNumber,
      },
      notes: {
        address: data.address,
        pincode: data.pincode,
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const checkout = (stage) => {
    setCurrentStage(stage);
  };
  return (
    <div
      className={`w-100 d-flex align-content-center flex-wrap prodectDetail-right-column`}
    >
      <div className="w-100 prodectDetail-configure-cotainer">
        <div
          className={`w-100 checkout-table-container ${
            currentStage === "overview" ? "show" : "hide"
          }`}
        >
          <table>
            <tbody>
              <tr>
                <td>
                  <p>24 Pin Cable</p>

                  <div className="checkout-color-row">
                    <span className="checkout-color-title">Color code:</span>
                    <div className="checkout-color-container">
                      {productOneWires.map((wire, i) => {
                        return (
                          <span key={i} className="checkout-color-column">
                            <img
                              src={`${process.env.PUBLIC_URL}images/cableColor/${wire}-wire.png`}
                              alt=""
                            />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </td>
                <td>
                  <p>₹{valueCalc[1].find(o => o.wire === productOneWires.length).value}</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>PCIE Cable</p>
                  <div className="checkout-color-row">
                    <span className="checkout-color-title">Color code:</span>
                    <div className="checkout-color-container">
                      {productTwoWires.map((wire, i) => {
                        return (
                          <span key={i} className="checkout-color-column">
                            <img
                              src={`${process.env.PUBLIC_URL}images/cableColor/${wire}-wire.png`}
                              alt=""
                            />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </td>
                <td>
                  {" "}
                  <p>₹{valueCalc[2].find(o => o.wire === productTwoWires.length).value}</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>CPU Cable</p>
                  <div className="checkout-color-row">
                    <span className="checkout-color-title">Color code:</span>
                    <div className="checkout-color-container">
                      {productThreeWires.map((wire, i) => {
                        return (
                          <span key={i} className="checkout-color-column">
                            <img
                              src={`${process.env.PUBLIC_URL}images/cableColor/${wire}-wire.png`}
                              alt=""
                            />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                </td>
                <td>
                  {" "}
                  <p>₹{valueCalc[3].find(o => o.wire === productThreeWires.length).value}</p>
                </td>
              </tr>

              <tr>
                <td>
                  <p>
                    <strong>Total Ammount:</strong>
                  </p>
                </td>
                <td>
                  {" "}
                  <p>₹{valueCalc[1].find(o => o.wire === productOneWires.length).value + valueCalc[2].find(o => o.wire === productTwoWires.length).value + valueCalc[3].find(o => o.wire === productThreeWires.length).value}</p>
                </td>
              </tr>

              <tr>
                <td colSpan="2">
                  <a
                    onClick={() => {
                      checkout("checkout");
                    }}
                    className="product-details-checkout-cta"
                  >
                    <i className="fa-solid fa-cart-shopping"></i>
                    <span>Checkout</span>
                  </a>
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <a
                    onClick={() => {
                      checkout("initial");
                    }}
                    className="back-button"
                  >
                    <i className="fa-solid fa-cart-shopping"></i>
                    <span>Back</span>
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          className={`checkout-form-container ${
            currentStage === "checkout" ? "show" : "hide"
          }`}
        >
          <form id="form-pay" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label htmlFor="customer-name">Customer Name</label>
              <input
                type="text"
                className="form-control"
                {...register("customerName", {
                  required: "Customer name is required!",
                  maxLength: {
                    value: 15,
                    message: "name must be less than 15 words",
                  },
                })}
                placeholder="Enter your name"
              />
              <span className="error-msg">
                {errors.customerName ? errors.customerName.message : ""}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="phone-number">Phone Number</label>
              <input
                type="number"
                className="form-control"
                {...register("mobileNumber", {
                  required: "Mobile Number is required",
                  minLength: {
                    value: 10,
                    message: "Mobile number must be 10 digits!",
                  },
                  maxLength: {
                    value: 10,
                    message: "Mobile number must be 10 digits!",
                  },
                })}
                placeholder="Enter phone Number"
              />
              <span className="error-msg">
                {errors.mobileNumber ? errors.mobileNumber.message : ""}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="customer-email">Email address</label>
              <input
                type="email"
                className="form-control"
                {...register("email", {
                  required: "Email is required",
                  pattern: /^\S+@\S+$/i,
                })}
                placeholder="Enter email"
              />
              <span className="error-msg">{errors.email ? errors.email.message : ""}</span>
              <br/>
              <small id="emailHelp" className="form-text text-muted">
                We'll never share your email with anyone else.
              </small>
            </div>
            <div className="form-group">
              <label htmlFor="customer-address">Customer Address</label>
              <input
                type="text"
                className="form-control"
                {...register("address", {
                  required: "Customer Address is required!",
                  maxLength: {
                    value: 30,
                    message: "Address must be less than 30 words",
                  },
                })}
                placeholder="Enter your full Address"
              />
              <span className="error-msg">{errors.address ? errors.address.message : ""}</span>
            </div>
            <div className="form-group">
              <label htmlFor="customer-pincode">Address Pincode</label>
              <input
                type="number"
                className="form-control"
                {...register("pincode", {
                  required: "Customer Address Pincode is required!",
                  maxLength: {
                    value: 7,
                    message: "Address Pincode must be less than 7 words",
                  },
                })}
                placeholder="Enter your Address pincode"
              />
              <span className="error-msg">{errors.pincode ? errors.pincode.message : ""}</span>
            </div>

            <button
            disabled={payDisable}
              id="pay-now"
              type="submit"
              className="btn btn-primary final-pay"
            >
              Proceed to Pay <span className="pay-amount"></span>
            </button>
            <a
              onClick={() => {
                checkout("overview");
              }}
              className="back-button"
            >
              <i className="fa-solid fa-cart-shopping"></i>
              <span>Back</span>
            </a>
            <br />
            <div
              id="rzp-logo"
              className="large d-flex justify-content-center w-100"
            >
              <img
                src="https://cdn.razorpay.com/static/assets/powered_by_razorpay.png"
                height="16px"
              />
            </div>
          </form>
        </div>
        <div className={`${currentStage === "initial" ? "show" : "hide"}`}>
          <div className={`w-100 detail-title-row`}>
            <h1 className="product-detail-title">Configurate</h1>
            <h6>Price Starting from ₹2500</h6>
          </div>
          <div className={`w-100 cable-color-container `}>
            <div className="w-100 step-row">
              <h4 className="step-title">
                Step 1: <span className="d-block">Choose your color</span>
              </h4>
            </div>
            <div className="w-100 cableColor-row">
              {wires.map((wire, i) => (
                <WireSelector key={i} wire={wire} />
              ))}
            </div>
          </div>
          <div className="w-100 d-flex justify-content-center product-details-checkout">
            <a
              onClick={() => {
                checkout("overview");
              }}
              className="product-details-checkout-cta"
            >
              <i className="fa-solid fa-cart-shopping"></i>
              <span>Checkout</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Configurator;
