import GLOBAL_ACTION_TYPES from "./global.type";

export const INITIAL_GLOBAL_STATE = {
    color:'black',
    mainProduct:'productOne',
    positionTwo:'productTwo',
    positionThree:'productThree',
    drawerStatus:'closed'
}

export const globalReducer = (state=INITIAL_GLOBAL_STATE,action={}) =>{

    const {type,payload} = action;

    switch(type){
        case GLOBAL_ACTION_TYPES.SET_GLOBAL_COLOR:
            return {...state,color:payload}
        case GLOBAL_ACTION_TYPES.SET_MAIN_PRODUCT:
            return {...state,mainProduct:payload}
        case GLOBAL_ACTION_TYPES.SET_POSITION_TWO:
            return {...state,positionTwo:payload}
        case GLOBAL_ACTION_TYPES.SET_POSITION_THREE:
            return {...state,positionThree:payload}
        case GLOBAL_ACTION_TYPES.SET_POSITION_FOUR:
            return {...state,positionFour:payload}           
        case GLOBAL_ACTION_TYPES.SET_DRAWER_STATUS:
            return {...state,drawerStatus:payload}
        default:
            return state

    }

}