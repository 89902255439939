import PRODUCT_ACTION_TYPES from "./product.type";

export const PRODUCT_INITIAL_STATE={

    productOneWireColors:[
        'black',
        'black',
        'black',
        'black',
        'black',
        'black',
        'black',
        'black',
        'black',
        'black',
        'black',
        'black',
    ],

    productOneConnectorColor:'black',
    productTwoWireColors:[
        'black',
        'black',
        'black',
        'black',
    ],

    productTwoConnectorColor:'black',
    productThreeWireColors:[
        'black',
        'black',
        'black',
        'black',
    ],

    productThreeConnectorColor:'black',
    

}

export const productReducer = (state=PRODUCT_INITIAL_STATE,action={}) => {

    const {type,payload} = action;

    switch(type){
        case PRODUCT_ACTION_TYPES.SET_PRODUCT_ONE_WIRE_COLOR:
            return {...state,productOneWireColors:payload};
        case PRODUCT_ACTION_TYPES.SET_PRODUCT_ONE_CONNECTOR_COLOR:
            return {...state,productOneConnectorColor:payload}
        case PRODUCT_ACTION_TYPES.SET_PRODUCT_TWO_WIRE_COLOR:
            return {...state,productTwoWireColors:payload};
        case PRODUCT_ACTION_TYPES.SET_PRODUCT_TWO_CONNECTOR_COLOR:
            return {...state,productTwoConnectorColor:payload}
        case PRODUCT_ACTION_TYPES.SET_PRODUCT_THREE_WIRE_COLOR:
            return {...state,productThreeWireColors:payload};
        case PRODUCT_ACTION_TYPES.SET_PRODUCT_THREE_CONNECTOR_COLOR:
            return {...state,productThreeConnectorColor:payload}
        default:
            return state
    }

}