import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMainProduct } from "../store/global/global.selector";
import {
  setProductThreeWireColors,
  setProductTwoWireColors,
} from "../store/product/product.action";
import {
  selectProductThreeWireColors,
  selectProductTwoWireColors,
} from "../store/product/product.selector";

const CableSelector = () => {
  const dispatch = useDispatch();
  const mainProduct = useSelector(selectMainProduct);
  const threeWires = useSelector(selectProductThreeWireColors);
  const twoWires = useSelector(selectProductTwoWireColors);
  const [currentWires, setCurrentWires] = useState(0);
  const [wires, setWires] = useState([]);

  useEffect(() => {
    if (mainProduct === "productThree") {
      setCurrentWires(threeWires);
      setWires([{'value':4,'name':'4 pin cable'}, {'value':8,'name':'8 pin cable'}]);
    } else if (mainProduct === "productTwo") {
      setCurrentWires(twoWires);
      setWires([{'value':4,'name':'8 pin cable'}, {'value':7,'name':'8+6 pin cable'}, {'value':8,'name':'8+8 pin cable'}, {'value':11,'name':'8+8+6 pin cable'}, {'value':12,'name':'8+8+8 pin cable'}]);
    }
  }, [mainProduct, threeWires, twoWires]);
  console.log(currentWires, "currentWires", wires.length);
  const changeCable = (event) => {
    const value = event.target.value;
    let newWire = 4;
    console.log(value, "valuevalue");
    switch (value) {
      case "4":
        newWire = new Array(4).fill("black");
        break;
      case "7":
        newWire = new Array(7).fill("black");
        break;
      case "8":
        newWire = new Array(8).fill("black");
        break;
      case "11":
        newWire = new Array(11).fill("black");
        break;
      case "12":
        newWire = new Array(12).fill("black");
        break;
    }

    console.log(newWire, "newWire", mainProduct);

    if (mainProduct === "productThree") {
      dispatch(setProductThreeWireColors(newWire));
    } else if (mainProduct === "productTwo") {
      dispatch(setProductTwoWireColors(newWire));
    }
  };

  return (
    <Fragment>
      <label>Cables</label>
      {wires.map((wire, i) => {
        return (
          <div key={i+mainProduct} className="radion-button-row second-radion-button-row">
            <div className="radion-button-column">
              <input
              key={i+mainProduct}
                checked={currentWires.length === wire.value ? true : false}
                onChange={changeCable}
                name="radAnswer"
                type="radio"
                value={wire.value}
                id={`color-${wire.value}-${mainProduct}`}
              />
              <label htmlFor={`color-${wire.value}-${mainProduct}`}>
                {wire.name}
              </label>
            </div>
          </div>
        );
      })}
    </Fragment>
  );
};

export default CableSelector;
