const PRODUCT_ACTION_TYPES = {
    SET_PRODUCT_ONE_WIRE_COLOR:'product-one/SET_PRODUCT_ONE_WIRE_COLOR',
    SET_PRODUCT_ONE_CONNECTOR_COLOR:'product-one/SET_PRODUCT_ONE_CONNECTOR_COLOR',
    SET_PRODUCT_TWO_WIRE_COLOR:'product-one/SET_PRODUCT_TWO_WIRE_COLOR',
    SET_PRODUCT_TWO_CONNECTOR_COLOR:'product-one/SET_PRODUCT_TWO_CONNECTOR_COLOR',
    SET_PRODUCT_THREE_WIRE_COLOR:'product-one/SET_PRODUCT_THREE_WIRE_COLOR',
    SET_PRODUCT_THREE_CONNECTOR_COLOR:'product-one/SET_PRODUCT_THREE_CONNECTOR_COLOR',
    SET_PRODUCT_FOUR_WIRE_COLOR:'product-one/SET_PRODUCT_FOUR_WIRE_COLOR',
    SET_PRODUCT_FOUR_CONNECTOR_COLOR:'product-one/SET_PRODUCT_FOUR_CONNECTOR_COLOR'
}
export default PRODUCT_ACTION_TYPES