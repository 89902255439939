const wires = [
    'black',
    'lightGray',
    'red',
    'lightGreen',
    'blue',
    'yellow',
    'megenta',
    'orange',
    'pink',
    'gray',
    // 'skyBlue',
    // 'redBlack',
    // 'lightYellow',
    // 'green',
    // 'deepOrange',
  
];

export default wires;