import GLOBAL_ACTION_TYPES from "./global.type"

export const setColor = (color) =>{
    return {type:GLOBAL_ACTION_TYPES.SET_GLOBAL_COLOR,payload:color}
}
export const setMainProduct = (product) =>{
    return {type:GLOBAL_ACTION_TYPES.SET_MAIN_PRODUCT,payload:product}
}
export const setPositionTwo = (product) =>{
    return {type:GLOBAL_ACTION_TYPES.SET_POSITION_TWO,payload:product}
}
export const setPositionThree = (product) =>{
    return {type:GLOBAL_ACTION_TYPES.SET_POSITION_THREE,payload:product}
}
export const setPositionFour = (product) =>{
    return {type:GLOBAL_ACTION_TYPES.SET_POSITION_FOUR,payload:product}
}
export const setDrawerStatus = (status) => {
    return {type:GLOBAL_ACTION_TYPES.SET_DRAWER_STATUS,payload:status}
}