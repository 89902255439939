import { useDispatch, useSelector } from "react-redux"
import { setMainProduct, setPositionFour, setPositionThree, setPositionTwo } from "../store/global/global.action"
import { selectMainProduct, selectPositionFour, selectPositionThree, selectPositionTwo } from "../store/global/global.selector"
import CableSelector from "./cable-selector.component"
import CombColor from "./comb-color.component"
import ConnectorColor from "./connector-color.component"

import ProductOne from "./product-one.component"
import ProductThree from "./product-three.component"
import ProductTwo from "./product-two.component"
import { useEffect, useState } from "react"

const ProductsContainer = () =>{

  const mainProduct = useSelector(selectMainProduct);
  const positionTwo = useSelector(selectPositionTwo)
  const positionThree = useSelector(selectPositionThree)
  const positionFour = useSelector(selectPositionFour)
  const dispatch = useDispatch()
  const [showCables,setCables] = useState(false)

  useEffect(()=>{
    if(mainProduct ==='productOne'){

      setCables(false)
    }else{
      setCables(true)
    }
  },[mainProduct])

  console.log(positionTwo,positionThree,positionFour,mainProduct,'position')
  const renderMainComponent = () =>{
    switch(mainProduct){
      case 'productOne':
        return <ProductOne/>
      case 'productTwo':
        return <ProductTwo/>
        case 'productThree':
        return <ProductThree/>
        default:
          return <ProductOne/>

    }
  }

  const renderPositionTwoComponent = () =>{
    switch(positionTwo){
      case 'productOne':
        return <ProductOne/>
      case 'productTwo':
        return <ProductTwo/>
        case 'productThree':
        return <ProductThree/>
        default:
          return <ProductOne/>

    }
  }

  const renderPositionThreeComponent = () =>{
    switch(positionThree){
      case 'productOne':
        return <ProductOne/>
      case 'productTwo':
        return <ProductTwo/>
        case 'productThree':
        return <ProductThree/>
        default:
          return <ProductOne/>

    }
  }

  const renderPositionFourComponent = () =>{
    switch(positionFour){
      case 'productOne':
        return <ProductOne/>
      case 'productTwo':
        return <ProductTwo/>
        case 'productThree':
        return <ProductThree/>
        default:
          return <ProductOne/>

    }
  }

  const handleMainProductFip = (product)=>{
console.log('handleMainProductFip')
    switch(product){
      case 'productTwo':
        dispatch(setPositionTwo(mainProduct))
        dispatch(setMainProduct(positionTwo));
        break;
        case 'productThree':
          dispatch(setPositionThree(mainProduct))
          dispatch(setMainProduct(positionThree));
          break;

    }
  
  }

    return (
        <div className="w-100 flex-shrink-0 d-flex align-items-center prodectDetail-left-column">
        <div className="w-100 d-flex flex-wrap prodectDetail-left-cotainer">
          <div className="thumb-imageContainer">
            <div className="d-flex justify-content-center thumb-imageWrapper">
            <div className="thumb-imgColumn" onClick={()=>handleMainProductFip('productTwo')}>
             {renderPositionTwoComponent()}
            </div>
            <div className="thumb-imgColumn" onClick={()=>handleMainProductFip('productThree')}>
             {renderPositionThreeComponent()}
            </div>
            </div>
          </div>
          <div className="product-view-container">
            <div className="w-100 step-row">
              <h4 className="step-title">Step 2: <span className="d-block">Click on cable to change color</span></h4>
            </div>
            <div className="wireGallery-view-container w-100 d-flex flex-wrap justify-content-center">
         {renderMainComponent()}
         </div>
          </div>
          <div className={`product-detail-select`}>
          {/* <CombColor/> */}
          <ConnectorColor/>
        {(showCables) ? <CableSelector/> : ""}  
            </div>
        </div>
      </div>
    )
}
export default ProductsContainer