import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./header.component";


const Homepage = () => {

    const navigate = useNavigate();
    const submitHandler = (e) => {
        e.preventDefault();
        navigate(`/config`);
      };
    return (
        <Fragment>
              <div className="container-fluid">
      <div className="row">
       <Header/>

<div className="w-100 cableSlider-section position-relative">

   <figure className="cableSlider-figure"><img src={`${process.env.PUBLIC_URL}/images/Sleeved-cables.jpg`}  className="w-100 h-100 objectCover"/></figure>
      <div className="container position-absolute cableSlider-container">

        <div className="cableSlider-content">
          <p className="cableSlider-subtitle">Your one-stop solution for </p>
          <h1 className="cableSlider-heading-title"><strong>Custom-sleeved cables</strong> that are built for perfection & are visually appealing. </h1>
          <p>We Ship across India</p>
          <div className="w-100 cableSlider-button-block">
            <p>Start configuring your cables today.</p>
            <button onClick={submitHandler} className="vpc_default_redgradint_cta cableSlider-getStarted-cta"><span>Get Started</span></button>
          </div>
          
        </div>
      </div>




</div>

      </div>
    </div>

            </Fragment>
    )
}
export default Homepage;