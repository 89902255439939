
import { useDispatch, useSelector } from "react-redux"
import { setDrawerStatus } from "../store/global/global.action"
import { selectDrawerStatus } from "../store/global/global.selector"

const MobileToggle = () => {

  const drawerStatus = useSelector(selectDrawerStatus)
  const dispatch = useDispatch()

  const toggleStatus = () =>{

if(drawerStatus ==='open'){

dispatch(setDrawerStatus('closed'))
}else{
  dispatch(setDrawerStatus('open'))
}

  }

    return (
        <div onClick={toggleStatus} className="mobile-configure">
        <button type="button" className="filter-button">
          <i className="fa-solid fa-filter"></i>
        
        </button>
      </div>
    )
}
export default MobileToggle