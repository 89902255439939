import logo from './logo.svg';
import './App.css';
import {Route,Routes} from 'react-router-dom'
import Container from './components/container.component';
import Homepage from './components/homepage';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Homepage/>}/>
      <Route path='/config' element={<Container/>}/>
    </Routes>
   
  );
}

export default App;
