import PRODUCT_ACTION_TYPES from "./product.type";

export const setProductOneWireColors = (wireColors) => {
   return { type:PRODUCT_ACTION_TYPES.SET_PRODUCT_ONE_WIRE_COLOR, payload:wireColors };
}
export const setProductOneConnectorColor = (color) =>{
   return {type:PRODUCT_ACTION_TYPES.SET_PRODUCT_ONE_CONNECTOR_COLOR,payload:color}
}

export const setProductTwoWireColors = (wireColors) => {
   return { type:PRODUCT_ACTION_TYPES.SET_PRODUCT_TWO_WIRE_COLOR, payload:wireColors };
}
export const setProductTwoConnectorColor = (color) =>{
   return {type:PRODUCT_ACTION_TYPES.SET_PRODUCT_TWO_CONNECTOR_COLOR,payload:color}
}

export const setProductThreeWireColors = (wireColors) => {
   return { type:PRODUCT_ACTION_TYPES.SET_PRODUCT_THREE_WIRE_COLOR, payload:wireColors };
}
export const setProductThreeConnectorColor = (color) =>{
   return {type:PRODUCT_ACTION_TYPES.SET_PRODUCT_THREE_CONNECTOR_COLOR,payload:color}
}


