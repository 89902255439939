import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerStatus } from "../store/global/global.action";
import { selectDrawerStatus } from "../store/global/global.selector";
import Configurator from "./configurator.component";
import Footer from "./footer.component";
import Header from "./header.component";
import MobileToggle from "./mobile-toggle.component";
import ProductsContainer from "./products-container.component";

const Container = () => {

  const drawerStatus = useSelector(selectDrawerStatus)
  const dispatch = useDispatch()

  const drawerClose = () => {
    dispatch(setDrawerStatus('closed'))
  }

    return (
        <Fragment>
    
    <div className={`container-fluid ${drawerStatus==='open' ? 'active-filter' : ''}`}>
    <div onClick={drawerClose} className="defalt-overlay position-fixed w-100 h-100"></div>
      <div className="row">
      <Header/>
        <div className="col-12 prodectDetail-section">
        <MobileToggle/>
          <div className="row">
            <div className="container">
              <div className="w-100 prodectDetail-container">
              <ProductsContainer/>
              <Configurator/>
               
              </div>
            </div>
          </div>
        </div>
      <Footer/>
      </div>
    </div>
        </Fragment>
    )


}

export default Container;